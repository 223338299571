import React from "react"
import Topic from "../../components/Topic"
import { Link } from "gatsby"

const Housing = () => (
  <Topic id="housing">
    <p>
      Adjusting to life in Canada can be very challenging. Finding suitable
      housing is perhaps one of the biggest challenges. It can be very difficult
      to find a place to live in most Canadian cities. Prices are typically very
      high and availability is low. There are also additional barriers when you
      are a newcomer. Not having a rental history or employment history in
      Canada, or not speaking strong English can make it more difficult. This
      section includes some information you should know when searching for safe
      and affordable housing. A local{" "}
      <Link to="/settling/settlement_agencies/">settlement agency</Link> can
      also help you find a place to stay and assist you in applying for
      government or subsidized housing.
    </p>
    <p>
      If you are a newly arrived refugee claimant, there are{" "}
      <a
        href="https://www.tinyurl.com/RefugeeHousesInCanada"
        target="_blank"
        rel="noreferrer"
      >
        shelters
      </a>{" "}
      specifically established to help you. Not only do they provide a safe
      place to live, they also provide services and support unique to the needs
      of refugee claimants. These shelters are a good initial housing option
      when you first arrive.
    </p>
    <p>
      Whether you are looking for an emergency shelter, an affordable rental
      unit, or learning about the rights and responsibilities of tenants and
      landlords - there are local organizations that can support you as you look
      for a place to live. See the links for some helpful information.
    </p>
  </Topic>
)

export default Housing
